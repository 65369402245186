import { ServiceFactory } from '@/services/ServiceFactory'
import moment from 'moment'
import { VbServiceFactory } from '@/services/VbServiceFactory'
const _billingService = VbServiceFactory.get('BillingService')
const _billingReportService = ServiceFactory.get('BillingReportService')
const _citiesService = ServiceFactory.get('CitiesService')
export default {
  data () {
    return {
      tab: null,
      date: new Date().toISOString().substr(0, 7),
      menu: false,
      menu2: false,
      qrDialog: false,
      date2: [],
      modal: false,
      panel: [],
      items: [
        'Bar tito',
        'Chiken burger',
        'Reserbar',
        'Bar-iedad'
      ],
      headers: [
        // {
        //   text: '',
        //   align: 'start',
        //   sortable: false,
        //   value: 'name',
        // },
        { text: 'Reservas pequeñas', value: 'small' },
        { text: 'Reservas medianas', value: 'medium' },
        { text: 'Reservas grandes', value: 'big' },
        { text: 'Total', value: 'total' }
      ],
      bookings: [
        {
          small: 15,
          medium: 12,
          big: 7,
          total: 34
        },
        {
          small: 15,
          medium: 12,
          big: 7,
          total: 34
        }
      ],
      billing: [],
      dateRange: null,
      loading: false,
      loading2: false,
      loadingQr: false,
      dialog: false,
      sendDialog: false,
      file: null,
      mailData: {},
      currentMonth: '',
      vbCurrentMonth: '',
      addMail: false,
      newMail: '',
      postData: {},
      qrPostData: {
        amount: 0,
        title: ''
      },
      createdQr: null,
      cities: [],
      cityId: null,
      billingLoading: false,
      filter: 'all',
      filters: [
        {
          name: 'Todos',
          value: 'all'
        },
        {
          name: 'Pagado',
          value: 'paid'
        },
        {
          name: 'No pagado',
          value: 'unpaid'
        },
        {
          name: 'Sin facturación',
          value: 'nobilling'
        }
      ],
      allBusiness: [],
      // videobrand
      panel2: [],
      videobrandBilling: [],
      date3: new Date().toISOString().substr(0, 7),
      menu3: false,
      total: {}
    }
  },
  mounted () {
    this.selectCity()
    this.init()
  },
  methods: {
    async selectCity () {
      this.cities = await _citiesService.index('Bolivia')
      // console.log(this.cities.cities)
      var lpID = ''
      this.cities.cities.forEach(el => {
        if (el.name === 'La Paz') {
          lpID = el.zbCityId
        }
      })
      this.cityId = lpID
      this.initialize()
    },
    async init () {
      console.log(this.$route)
      if (this.$route.params.view) {
        // console.log('vista')
        this.tab = 1
      }
      try {
        console.log(this.date3)
        const reqDate = this.date3.split('-')
        console.log(reqDate)
        const resp = await _billingService.index(reqDate[0], reqDate[1])
        console.log(resp)
        this.videobrandBilling = resp.billing
        this.total = resp.total
        const mth = this.date3.slice(5)
        switch (mth) {
          case '01':
            this.vbCurrentMonth = 'Enero'
            break
          case '02':
            this.vbCurrentMonth = 'Febrero'
            break
          case '03':
            this.vbCurrentMonth = 'Marzo'
            break
          case '04':
            this.vbCurrentMonth = 'Abril'
            break
          case '05':
            this.vbCurrentMonth = 'Mayo'
            break
          case '06':
            this.vbCurrentMonth = 'Junio'
            break
          case '07':
            this.vbCurrentMonth = 'Julio'
            break
          case '08':
            this.vbCurrentMonth = 'Agosto'
            break
          case '09':
            this.vbCurrentMonth = 'Septiembre'
            break
          case '10':
            this.vbCurrentMonth = 'Octubre'
            break
          case '11':
            this.vbCurrentMonth = 'Noviembre'
            break
          case '12':
            this.vbCurrentMonth = 'Diciembre'
            break
          default:
            break
        }
      } catch (error) {
        console.log(error)
      }
    },
    async initialize () {
      // console.log(this.date)
      this.filter = 'all'
      try {
        this.billingLoading = true
        var monthDays = moment(this.date.toString(), 'YYYY-MM').daysInMonth()
        this.dateRange = {
          dateStart: this.date + '-01',
          dateEnd: this.date + '-' + monthDays
        }
        this.date2 = [ this.date + '-01', this.date + '-' + monthDays ]
        // this.dateRange = this.getFullDate(this.date)
        // console.log(this.dateRange)
        this.billing = await _billingReportService.index(this.dateRange, this.cityId)
        // console.log(this.billing)
        this.allBusiness = this.billing.billing
        this.billingLoading = false
        const mth = this.date.slice(5)
        switch (mth) {
          case '01':
            this.currentMonth = 'Enero'
            break
          case '02':
            this.currentMonth = 'Febrero'
            break
          case '03':
            this.currentMonth = 'Marzo'
            break
          case '04':
            this.currentMonth = 'Abril'
            break
          case '05':
            this.currentMonth = 'Mayo'
            break
          case '06':
            this.currentMonth = 'Junio'
            break
          case '07':
            this.currentMonth = 'Julio'
            break
          case '08':
            this.currentMonth = 'Agosto'
            break
          case '09':
            this.currentMonth = 'Septiembre'
            break
          case '10':
            this.currentMonth = 'Octubre'
            break
          case '11':
            this.currentMonth = 'Noviembre'
            break
          case '12':
            this.currentMonth = 'Diciembre'
            break
          default:
            break
        }
      } catch (error) {
        console.log(error)
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: 'Algo salió mal al obtener la facturación.'
        })
        this.billingLoading = false
      }
    },
    async init2 () {
      try {
        this.dateRange = {
          dateStart: this.date2[0],
          dateEnd: this.date2[1]
        }
        this.billing = await _billingReportService.index(this.dateRange)
      } catch (error) {
        console.log(error)
      }
    },
    // async sendEmail (item) {
    //   item.date = new Date().toISOString().substr(0, 10)
    //   this.mailData = item
    //   this.dialog = true
    //   // console.log(item)
    // },
    async sendMail (item) {
      item.date = new Date().toISOString().substr(0, 10)
      this.mailData = item
      // this.dialog = true
      console.log(item)
    },
    cancelSending () {
      this.sendDialog = false
      this.loading = false
      this.addMail = false
      this.newMail = ''
    },
    closeQrDialog () {
      this.qrPostData = {
        amount: 0,
        title: ''
      }
      this.qrDialog = false
      this.createdQr = null
    },
    async submitQr () {
      try {
        this.loadingQr = true
        this.qrPostData.amount = Number(this.qrPostData.amount)
        const send = await _billingReportService.generateQr(this.qrPostData)
        this.loadingQr = false
        if (send.success) {
          this.$notify({
            title: 'Éxito: ',
            type: 'success',
            text: 'Se creo el QR de pago de forma exitosa'
          })
          this.createdQr = send.qrImage
          this.qrPostData.amount = 0
          this.qrPostData.title = ''
        } else {
          this.$notify({
            title: 'Error:',
            type: 'error',
            text: send.msg + ', no se pudo enviar.'
          })
        }
      } catch (error) {
        this.loadingQr = false
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.message + ', no se pudo enviar.'
        })
        this.cancelSending()
      }
    },
    async submitSending () {
      try {
        this.postData.secondMail = this.newMail
        console.log(this.postData)
        const send = await _billingReportService.sendEmail(this.postData)
        console.log(send)
        this.$swal(
          'Correo enviado',
          '',
          'success'
        ).then(async (result) => {
          this.cancelSending()
          this.file = null
        })
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.message + ', no se pudo enviar.'
        })
        this.cancelSending()
        // this.loading = false
      }
    },
    async submitData (item) {
      // console.log(this.mailData)
      // const item = this.mailData
      this.sendDialog = true
      var currDate = new Date().toISOString().substr(0, 10)
      item.date = currDate.toString()
      item.dateStart = this.dateRange.dateStart
      item.dateEnd = this.dateRange.dateEnd
      // item.file = this.file
      this.loading = true
      console.log(item)
      this.postData = item
    },
    getFullDate (date) {
      const month = date.slice(5)
      console.log(month)
      const fullDate = {
        dateStart: date + '-01',
        dateEnd: ''
      }
      switch (month) {
        case '01':
          fullDate.dateEnd = date + '-31'
          break
        case '02':
          fullDate.dateEnd = date + '-28'
          break
        case '03':
          fullDate.dateEnd = date + '-31'
          break
        case '04':
          fullDate.dateEnd = date + '-30'
          break
        case '05':
          fullDate.dateEnd = date + '-31'
          break
        case '06':
          fullDate.dateEnd = date + '-30'
          break
        case '07':
          fullDate.dateEnd = date + '-31'
          break
        case '08':
          fullDate.dateEnd = date + '-31'
          break
        case '09':
          fullDate.dateEnd = date + '-30'
          break
        case '10':
          fullDate.dateEnd = date + '-31'
          break
        case '11':
          fullDate.dateEnd = date + '-30'
          break
        case '12':
          fullDate.dateEnd = date + '-31'
          break
        default:
          break
      }
      return fullDate
    },
    cancelFile () {
      this.dialog = false
      this.file = null
    },
    // Create an array the length of our items
    // with all values as true
    all () {
      this.panel = [...Array(this.items).keys()].map((k, i) => i)
    },
    // Reset the panel
    none () {
      this.panel = []
    },
    async generate (item) {
      console.log(item.zbBarId, this.dateRange)
      this.loading2 = true

      const { value: discountValues } = await this.$swal({
        title: '¿Agregar descuento?',
        html: '<label>Cantidad (Bs.):</label><input id="swal-input1" class="swal2-input">' +
              '<label>Motivo:</label><input id="swal-input2" class="swal2-input">',
        focusConfirm: false,
        preConfirm: () => {
          return [
            document.getElementById('swal-input1').value,
            document.getElementById('swal-input2').value
          ]
        }
      })

      var discountAmount = 0
      var discReason = ''

      if (discountValues) {
        if (discountValues[0]) {
          console.log('valor 1')
          discountAmount = parseFloat(discountValues[0])
        } else {
          console.log('no valor 1')
        }
        if (discountValues[1]) {
          console.log('valor 2')
          discReason = discountValues[1]
        } else {
          console.log('no valor 2')
        }
      }

      try {
        const postGenerate = {
          zbBarId: item.zbBarId,
          dateStart: this.dateRange.dateStart,
          dateEnd: this.dateRange.dateEnd,
          billingDiscount: discountAmount,
          discountReason: discReason
        }
        const generateResp = await _billingReportService.generate(postGenerate)
        this.initialize()
        // this.qrImg = true
        this.loading2 = false
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
        this.initialize()
        this.loading2 = false
      }
    },
    async changeState (id) {
      console.log(id)
      try {
        const periodResp = await _billingReportService.periodState(id)
        console.log(periodResp)
        this.$notify({
          type: 'success',
          text: 'Estado modificado correctamente'
        })
        this.initialize()
      } catch (error) {
        console.log(error)
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
        this.initialize()
      }
    },
    async removeBillinPeriod (id) {
      console.log(id)
      const { value: Pass } = await this.$swal({
        title: `Eliminar la factura`,
        input: 'password',
        inputPlaceholder: 'Ingrese su contraseña para eliminar la factura',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Debes escribir tu contraseña'
          }
        }
      })
      if (Pass) {
        console.log(Pass)
        try {
          const cancelResp = await _billingReportService.removeBilling(id, { password: Pass })
          console.log(cancelResp)
          this.$notify({
            type: 'success',
            text: 'Reserva cancelada correctamente'
          })
          this.initialize()
        } catch (error) {
          console.log(error)
          this.$notify({
            type: 'error',
            text: 'Algo salió mal al eliminar la factura, vuelva a intentarlo nuevamente' + error.msg
          })
        }
      }
    },
    viewDebt (items) {
      console.log(items)
    },
    changeFilter () {
      // const ab = this.billing.billing
      // this.allBusiness = ab
      if (this.filter === 'all') {
        // this.initialize()
        this.billing.billing = this.allBusiness
      }
      if (this.filter === 'paid') {
        this.billing.billing = this.allBusiness
        this.billing.billing = this.billing.billing.filter((value, key) => {
          if (value.billingPeriod) {
            if (!value.billingPeriod.pendingState) {
              return value
            }
          }
        })
      }
      if (this.filter === 'unpaid') {
        this.billing.billing = this.allBusiness
        this.billing.billing = this.billing.billing.filter((value, key) => {
          if (value.billingPeriod) {
            if (value.billingPeriod.pendingState) {
              return value
            }
          }
        })
      }
      if (this.filter === 'nobilling') {
        this.billing.billing = this.allBusiness
        this.billing.billing = this.billing.billing.filter((value, key) => {
          if (!value.billingPeriod) {
            return value
          }
        })
      }
    },
    newProject () {
      this.$router.push({ name: 'videobrand' })
    },
    viewProject (id) {
      this.$router.push({ name: 'info', params: { id } }).catch(() => {})
    },
    tabClick () {
      console.log(this.tab)
    }
  }
}
